import { FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import { TYPE_HELPER, TYPE_PERSONAL } from 'common/User';
import { TYPE_ORGANIZATION } from 'components/Common/User';
import Langs from 'components/Const/Lang';
import ModalLocation from 'components/ModalLocation';
import React, { useEffect, useState } from 'react';
import { getLocation, getUserInfo, postTopic, putTopic } from 'pages/api';
import _ from "lodash"

const LocationFilterModal = ({ formik, lang="vi" }) => {
    const [listCity, setListCity] = useState([])
    const [listDistrict, setListDistrict] = useState([]);

    useEffect(() => {
        getLocationList({ type: 3, allPage: true, sortedBy: JSON.stringify([{ "key": "DISPLAY_ORDER", "reverse": false }, { "key": "NAME", "reverse": false }]) }, setListCity, 'haveAll')    
    }, [])

    useEffect(() => {
        if (typeof(formik?.values?.cityId) !== "object") {
            let obj = _.find(listCity, ["id", formik?.values?.cityId * 1])
            if (obj && Object.keys(obj)?.length > 0) {
                formik.setFieldValue("cityId", obj)
                getLocationList(
                    { type: 2, parentId: obj?.id, allPage: true, sortedBy: JSON.stringify([{ "key": "DISPLAY_ORDER", "reverse": false }, { "key": "NAME", "reverse": false }]) },
                    setListDistrict
                );
            }
        }
    }, [formik?.values?.cityId, listCity])

    useEffect(() => {
        if (formik?.values?.districtId && typeof(formik?.values?.districtId) !== "object") {
            let obj = _.find(listDistrict, ["id", formik?.values?.districtId * 1])
            if (obj && Object.keys(obj)?.length > 0) formik.setFieldValue("districtId", obj)
        }
    }, [formik?.values?.districtId, listDistrict])
    
    const getLocationList = (params, stateName, key) => {
        getLocation(params).then(req => {
            let data = req?.data?.data;
            stateName(data)
        }).catch(err => {

        })
    }

    const handleChangeCity = (e) => {
        if (e.target.value) {
            let parentId = e?.target?.value?.id
            getLocationList(
                { type: 2, parentId, allPage: true, sortedBy: JSON.stringify([{ "key": "DISPLAY_ORDER", "reverse": false }, { "key": "NAME", "reverse": false }]) },
                setListDistrict
            );
        }
        formik.setFieldValue("districtId", undefined);
        formik.handleChange(e);
    };

    const renderItemSelect = (data, key) => {
        return data.map(item => {
            return (
                <MenuItem value={key === 'object' ? item : item.name} key={item.id}>{item.name}</MenuItem>
            )
        })
    }

    return (
        <>
            <FormControl fullWidth sx={{ marginBottom: "8px" }}>
                <InputLabel id="cityId-label" size="small">{Langs?.tinh_thanh?.[lang]}</InputLabel>
                <Select
                    variant="outlined"
                    name="cityId"
                    id="cityId"
                    value={formik?.values?.cityId ?? []}
                    onChange={handleChangeCity}
                    size="small"
                    error={
                        formik?.touched?.cityId &&
                        Boolean(formik?.errors?.cityId)
                    }
                    input={<OutlinedInput label={`${Langs?.tinh_thanh?.[lang]}`} />}
                    labelId="cityId-label"
                    sx={{ backgroundColor: "#fff" }}
                >
                    <MenuItem value={""} disabled>
                        {Langs?.tinh_thanh?.[lang]}
                    </MenuItem>
                    {renderItemSelect(listCity, 'object')}
                </Select>
            </FormControl>

            <FormControl fullWidth sx={{ marginBottom: "8px" }}>
                <InputLabel id="districtId-label" size="small">{Langs?.quan_huyen?.[lang]}</InputLabel>
                <Select
                    variant="outlined"
                    name="districtId"
                    id="districtId"
                    value={formik?.values?.districtId ?? []}
                    onChange={formik?.handleChange}
                    size="small"
                    error={
                        formik?.touched?.districtId &&
                        Boolean(formik?.errors?.districtId)
                    }
                    input={<OutlinedInput label={`${Langs?.quan_huyen?.[lang]}`} />}
                    labelId="districtId-label"
                    sx={{ backgroundColor: "#fff" }}
                >
                    <MenuItem value={""} disabled>
                        {Langs?.quan_huyen?.[lang]}
                    </MenuItem>
                    {renderItemSelect(listDistrict, 'object')}
                </Select>
            </FormControl>
        </>
    );
};

export default LocationFilterModal;