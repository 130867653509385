import React from "react";
import { useFormik } from "formik";
import { Button, Stack } from "@mui/material";
import router from "next/router";
import qs from "query-string";
import moment from "moment-mini";
import _ from "lodash";
import {
  CATE_GIVE,
  CATE_PUBLIC,
  CATE_TAKE,
  STATUS_ACTIVE,
  TYPE_NEWS,
} from "components/Common/Topic";
import { TYPE_HELPER } from "common/User";
import { TYPE_ORGANIZATION } from "components/Common/User";
import Langs from "components/Const/Lang";

const FilterForm = ({
  visible,
  closeModal,
  filter,
  children,
  directLink = "/",
  isNews,
  isMap,
  lang = "vi",
  keyTextFilter,
}) => {
  const initFilter = !filter?.categoryId
    ? {
        allPage: isMap ? true : undefined,
        types: `[${TYPE_HELPER?.id},${TYPE_ORGANIZATION?.id}]`,
        name: filter.name ?? undefined,
      }
    : {
        categoryId: filter.categoryId ?? undefined,
        isAvailable: filter?.categoryId * 1 === TYPE_NEWS?.id ? undefined : 1, // 1: bai viet chua het han
        allPage: isMap ? true : undefined,
        memberTypes:
          filter?.categoryId * 1 === TYPE_NEWS?.id
            ? `[${TYPE_ORGANIZATION?.id}, ${TYPE_HELPER.id}]`
            : undefined,
        types:
          filter?.categoryId * 1 === TYPE_NEWS?.id
            ? `[${CATE_PUBLIC?.id}]`
            : undefined,
        creatorName:
          !isMap && filter.creatorName ? filter.creatorName : undefined,
        title: !isMap && filter.title ? filter.title : undefined,
      };

  const formik = useFormik({
    initialValues: {
      ...filter,
      workingFields: filter.workingFields
        ? isMap
          ? filter.workingFields
          : JSON.parse(filter.workingFields)
        : undefined,
      categories: filter.categories
        ? isMap
          ? filter.categories
          : JSON.parse(filter.categories)
        : undefined,
      formOfSupport: filter.formOfSupport
        ? isMap
          ? filter.formOfSupport
          : JSON.parse(filter.formOfSupport)
        : undefined,
      memberTypes:
        filter?.categoryId * 1 !== TYPE_NEWS?.id && filter.memberTypes
          ? JSON.parse(filter.memberTypes) //?.map((item) => item + "")
          : undefined,
      career: filter.career
        ? isMap
          ? filter.career
          : JSON.parse(filter.career)
        : undefined,
      jobType: filter.jobType
        ? isMap
          ? filter.jobType
          : JSON.parse(filter.jobType)
        : undefined,
      payForm: filter.payForm
        ? isMap
          ? filter.payForm
          : JSON.parse(filter.payForm)
        : undefined,
      transportProductType: filter.transportProductType
        ? isMap
          ? filter.transportProductType
          : JSON.parse(filter.transportProductType)
        : undefined,
      placeType: filter.placeType
        ? isMap
          ? filter.placeType
          : JSON.parse(filter.placeType)
        : undefined,
    },
    onSubmit: (values) => {
      let clone = _.cloneDeep(values);
      if (values?.categories?.length > 0) {
        clone.categories = JSON.stringify(values.categories);
      }
      if (values?.formOfSupport?.length > 0) {
        clone.formOfSupport = JSON.stringify(values.formOfSupport);
      }
      if (values?.transportProductType?.length > 0) {
        clone.transportProductType = JSON.stringify(
          values.transportProductType
        );
      }
      if (values?.career?.length > 0) {
        clone.career = JSON.stringify(values.career);
      }
      if (values?.jobType?.length > 0) {
        clone.jobType = JSON.stringify(values.jobType);
      }
      if (values?.payForm?.length > 0) {
        clone.payForm = JSON.stringify(values.payForm);
      }
      if (values?.placeType?.length > 0) {
        clone.placeType = JSON.stringify(values.placeType);
      }
      if (filter.categoryId * 1 === TYPE_NEWS?.id) {
        // clone.memberTypes = isMap
        //   ? filter.memberTypes
        //   : filter.memberTypes && JSON.stringify(filter.memberTypes);

        clone.memberTypes = filter.memberTypes;
      } else {
        clone.memberTypes =
          values?.memberTypes?.length > 0 && JSON.stringify(values.memberTypes);
      }
      if (values?.workingFields?.length > 0) {
        clone.workingFields = JSON.stringify(values?.workingFields);
      }
      if (values?.createdFrom) {
        clone.createdFrom = moment(clone.createdFrom).format("YYYY-MM-DD");
      }
      if (values?.createdTo) {
        clone.createdTo = moment(clone.createdTo).format("YYYY-MM-DD");
      }
      if (values?.createdTo) {
        clone.createdTo = moment(clone.createdTo).format("YYYY-MM-DD");
      }
      if (values?.cityId?.id) {
        clone.cityId = values?.cityId?.id;
      }
      if (values?.districtId?.id) {
        clone.districtId = values?.districtId?.id;
      } else {
        delete filter.districtId;
      }
      let _filter = _.pickBy({ ...filter, ...clone }, _.identity);
      delete _filter.addressLocation;
      router.push(
        `${directLink}${
          Object.keys(_filter).length > 0 ? "?" + qs.stringify(_filter) : ""
        }`
      );
      closeModal();
    },
  });

  const onReset = () => {
    let resetValues = _.reduce(
      filter,
      (result, field, key) => {
        result[key] = undefined;
        return result;
      },
      {}
    );
    formik.setValues({
      ...resetValues,
      ...initFilter,
    });
    formik.submitForm();
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {children(formik)}
      <Stack direction="row" justifyContent="center" mt={1} spacing={2}>
        <Button
          size="medium"
          variant="outlined"
          sx={{ width: "100%" }}
          type="reset"
          onClick={onReset}
        >
          {Langs?.xoa_bo_loc?.[lang]}
        </Button>
        <Button
          size="medium"
          variant="contained"
          sx={{ backgroundColor: "#64B5EC", width: "100%" }}
          type="submit"
        >
          {Langs?.tim_kiem?.[lang]}
        </Button>
      </Stack>
    </form>
  );
};

export default FilterForm;
