import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
} from "@mui/material";
import Langs from "components/Const/Lang";
import React, { useEffect, useState } from "react";
import { getLocation } from "pages/api";
import _ from "lodash";
import { useRouter } from "next/router";
import cookies from "next-cookies";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 320,
  bgcolor: "#fff",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const ModalLocation = ({
  formik,
  disabled,
  label,
  required,
  name,
  objName,
  listCity,
  data,
  isGetAll,
  textlabel,
  handleChange,
  requiredCity,
}) => {
  const [openAddress, setOpenAddress] = useState(false);
  const [listDistrict, setListDistrict] = useState([]);
  const [listWard, setListWard] = useState([]);
  const router = useRouter();
  let lang = cookies("lang")?.lang || process.env.NEXT_PUBLIC_DEFAULT_LANGUAGE;

  useEffect(() => {
    if (openAddress && formik?.values?.[objName]?.city?.id) {
      getLocationList(
        {
          type: 2,
          parentId: formik?.values?.[objName]?.city?.id,
          allPage: true,
          sortedBy: JSON.stringify([
            { key: "DISPLAY_ORDER", reverse: false },
            { key: "NAME", reverse: false },
          ]),
        },
        setListDistrict
      );

      if (formik?.values?.[objName]?.district?.id) {
        getLocationList(
          {
            type: 1,
            parentId: formik?.values?.[objName]?.district?.id,
            allPage: true,
            sortedBy: JSON.stringify([
              { key: "DISPLAY_ORDER", reverse: false },
              { key: "NAME", reverse: false },
            ]),
          },
          setListWard
        );
      }
    }

    formik.setFieldValue("city", formik?.values?.[objName]?.city ?? undefined);
    formik.setFieldValue(
      "district",
      formik?.values?.[objName]?.district ?? undefined
    );
    formik.setFieldValue("ward", formik?.values?.[objName]?.ward ?? undefined);
    formik.setFieldValue(
      "detail",
      formik?.values?.[objName]?.detail ?? undefined
    );
  }, [openAddress]);

  const getLocationList = (params, stateName) => {
    getLocation(params)
      .then((req) => {
        stateName(req?.data?.data);
      })
      .catch((err) => {});
  };

  const openModalAddress = () => {
    setOpenAddress(true);
  };

  const closeModalAddress = () => {
    // let address = [formik?.values?.detail, formik?.values?.ward, formik?.values?.district, formik?.values?.city]
    let address = [
      formik?.values?.detail,
      formik?.values?.ward?.name,
      formik?.values?.district?.name,
      formik?.values?.city?.name,
    ];

    let obj = {
      // cityId: _.find(listCity, ['name', formik?.values?.city])?.id,
      // districtId: _.find(listDistrict, ['name', formik?.values?.district])?.id,
      // wardId: _.find(listWard, ['name', formik?.values?.ward])?.id,
      city: formik?.values?.city,
      district: formik?.values?.district,
      ward: formik?.values?.ward,
      detail: formik?.values?.detail,
    };
    formik.setFieldValue([name], _.compact(address)?.join(", "));
    formik.setFieldValue([objName], obj);
    // formik.setFieldValue('city', "")
    // formik.setFieldValue('district', "")
    // formik.setFieldValue('ward', "")
    // formik.setFieldValue('detail', "")
    // formik.setFieldValue('city', {})
    // formik.setFieldValue('district', {})
    // formik.setFieldValue('ward', {})
    // formik.setFieldValue('detail', {})
    setOpenAddress(false);
  };

  const handleChangeCity = (e) => {
    if (e.target.value) {
      // let parentId = _.find(listCity, ['name', e?.target?.value])?.id
      let parentId = e.target.value?.id;
      getLocationList(
        {
          type: 2,
          parentId,
          allPage: true,
          sortedBy: JSON.stringify([
            { key: "DISPLAY_ORDER", reverse: false },
            { key: "NAME", reverse: false },
          ]),
        },
        setListDistrict
      );
    }
    formik.setFieldValue("ward", undefined);
    formik.setFieldValue("district", undefined);
    handleChange(e);
  };

  const handleChangeDistrict = (e) => {
    // let parentId = _.find(listDistrict, ['name', e?.target?.value])?.id
    let parentId = e.target.value?.id;
    getLocationList(
      {
        type: 1,
        parentId,
        allPage: true,
        sortedBy: JSON.stringify([
          { key: "DISPLAY_ORDER", reverse: false },
          { key: "NAME", reverse: false },
        ]),
      },
      setListWard
    );
    formik.setFieldValue("ward", undefined);
    handleChange(e);
  };

  const renderItemSelect = (data) => {
    return data?.map((item) => {
      return (
        <MenuItem value={{ id: item?.id, name: item?.name }} key={item.id}>
          {item.name}
        </MenuItem>
      );
    });
  };

  const renderErr = () => {
    let error = "";
    if (!formik?.values?.ward && !required)
      error = `${Langs?.phuong_xa?.[lang]} ${label} ${Langs?.la_bat_buoc?.[lang]}`;
    if (!formik?.values?.district && !required)
      error = `${Langs?.quan_huyen?.[lang]} ${label} ${Langs?.la_bat_buoc?.[lang]}`;
    if (!formik?.values?.city && !requiredCity)
      error = `${Langs?.tinh_thanh?.[lang]} ${label} ${Langs?.la_bat_buoc?.[lang]}`;
    if (!formik?.values?.detail && !required)
      error = `${Langs?.so_nha_ten_duong?.[lang]} ${label} ${Langs?.la_bat_buoc?.[lang]}`;
    if (formik?.values?.[name]?.length === 0)
      error = `${label} ${Langs?.la_bat_buoc?.[lang]}`;
    return error;
  };

  return (
    <>
      <FormControl fullWidth sx={{ marginBottom: "8px" }}>
        <InputLabel htmlFor={name} size="small">
          {label + (requiredCity ? "*" : "")}
        </InputLabel>
        <OutlinedInput
          id={name}
          name={name}
          value={formik?.values?.[name] ?? ""}
          onClick={openModalAddress}
          error={formik?.touched?.[name] && renderErr()?.length > 0}
          size="small"
          label={label + (requiredCity ? "*" : "")}
          disabled={disabled}
          sx={{ backgroundColor: "#fff" }}
        />
        {/* <FormHelperText style={{ color: "red" }}>{formik.touched?.[name] && renderErr()}</FormHelperText> */}
      </FormControl>

      <Modal
        open={openAddress}
        onClose={closeModalAddress}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style }}>
          <h3 style={{ margin: "0" }}>{label}</h3>
          <FormControl
            size="small"
            sx={{ width: "100%", marginBottom: "8px", marginTop: "16px" }}
          >
            <Select
              variant="outlined"
              name="city"
              id="city"
              value={formik?.values?.city?.name ?? []}
              onChange={handleChangeCity}
              size="small"
              error={formik?.touched?.city && Boolean(formik?.errors?.city)}
              displayEmpty
              renderValue={(selected) => {
                if (!selected || selected?.length === 0) {
                  return (
                    <div style={{ opacity: 0.5 }}>
                      {Langs?.tinh_thanh?.[lang]} {requiredCity ? "*" : ""}
                    </div>
                  );
                }

                return selected;
              }}
              disabled={disabled}
            >
              <MenuItem value={""} disabled>
                {Langs?.tinh_thanh?.[lang]} {requiredCity ? "*" : ""}
              </MenuItem>
              {isGetAll && (
                <MenuItem value={Langs?.tat_ca?.[lang]}>
                  {Langs?.tat_ca?.[lang]}
                </MenuItem>
              )}
              {renderItemSelect(listCity)}
            </Select>
            <FormHelperText style={{ color: "red", marginLeft: 0 }}>
              {!formik?.values?.city && requiredCity
                ? `${Langs?.tinh_thanh?.[lang]} ${Langs?.la_bat_buoc?.[lang]}`
                : ""}
            </FormHelperText>
          </FormControl>

          <FormControl size="small" sx={{ width: "100%", marginBottom: "8px" }}>
            <Select
              variant="outlined"
              name="district"
              id="district"
              value={formik?.values?.district?.name ?? []}
              onChange={handleChangeDistrict}
              size="small"
              error={
                formik?.touched?.district && Boolean(formik?.errors?.district)
              }
              displayEmpty
              renderValue={(selected) => {
                if (!selected || selected?.length === 0) {
                  return (
                    <div style={{ opacity: 0.5 }}>
                      {Langs?.quan_huyen?.[lang]} {required ? "*" : ""}
                    </div>
                  );
                }

                return selected;
              }}
              disabled={disabled}
            >
              <MenuItem value={""} disabled>
                {Langs?.quan_huyen?.[lang]} {required ? "*" : ""}
              </MenuItem>
              {isGetAll && (
                <MenuItem value={Langs?.tat_ca?.[lang]}>
                  {Langs?.tat_ca?.[lang]}
                </MenuItem>
              )}
              {renderItemSelect(listDistrict)}
            </Select>
            <FormHelperText style={{ color: "red", marginLeft: 0 }}>
              {!formik?.values?.district && required
                ? `${Langs?.quan_huyen?.[lang]} ${Langs?.la_bat_buoc?.[lang]}`
                : ""}
            </FormHelperText>
          </FormControl>

          <FormControl size="small" sx={{ width: "100%", marginBottom: "8px" }}>
            <Select
              variant="outlined"
              name="ward"
              id="ward"
              value={formik?.values?.ward?.name ?? []}
              onChange={handleChange}
              size="small"
              error={formik?.touched?.ward && Boolean(formik?.errors?.ward)}
              displayEmpty
              renderValue={(selected) => {
                if (!selected || selected?.length === 0) {
                  return (
                    <div style={{ opacity: 0.5 }}>
                      {Langs?.phuong_xa?.[lang]} {required ? "*" : ""}
                    </div>
                  );
                }

                return selected;
              }}
              disabled={disabled}
            >
              <MenuItem value={""} disabled>
                {Langs?.phuong_xa?.[lang]} {required ? "*" : ""}
              </MenuItem>
              {isGetAll && (
                <MenuItem value={Langs?.tat_ca?.[lang]}>
                  {Langs?.tat_ca?.[lang]}
                </MenuItem>
              )}
              {renderItemSelect(listWard)}
            </Select>
            <FormHelperText style={{ color: "red", marginLeft: 0 }}>
              {!formik?.values?.ward && required
                ? `${Langs?.phuong_xa?.[lang]} ${Langs?.la_bat_buoc?.[lang]}`
                : ""}
            </FormHelperText>
          </FormControl>

          <FormControl
            variant="standard"
            sx={{ width: "100%", marginBottom: "24px" }}
          >
            <OutlinedInput
              id="detail"
              name="detail"
              value={formik?.values?.detail ?? ""}
              onChange={handleChange}
              error={formik?.touched?.detail && Boolean(formik?.errors?.detail)}
              size="small"
              className="pb-1"
              placeholder={`${
                textlabel ? textlabel : Langs?.so_nha_ten_duong?.[lang]
              } ${required ? "*" : ""}`}
              disabled={disabled}
            />
            <FormHelperText style={{ color: "red", marginLeft: 0 }}>
              {!formik?.values?.detail && required
                ? `${Langs?.so_nha_ten_duong?.[lang]} ${Langs?.la_bat_buoc?.[lang]}`
                : ""}
            </FormHelperText>
          </FormControl>

          {!disabled && (
            <Button
              size="medium"
              variant="contained"
              className="btn-login"
              onClick={closeModalAddress}
              sx={{ backgroundColor: "#64B5EC", width: "100%" }}
            >
              {Langs?.hoan_tat?.[lang]}
            </Button>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default ModalLocation;
